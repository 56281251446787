import React from "react";
import vCardsJS from "vcards-js";
import axios from "axios";
import { Buffer } from 'buffer';


export default function SaveContact(props) {
  console.log("props.data", props.data);

  // const downloadTxtFile = (vcfText) => {
  //   const element = document.createElement("a");
  //   const file = new Blob([vcfText], { type: "text/vcard;charset=utf-8" });
  //   element.href = URL.createObjectURL(file);
  //   element.download = "myContact.vcf";
  //   document.body.appendChild(element);
  //   element.click();
  // };
  // const downloadTxtFile = (vcfText) => {
  //   // Encode the vCard text as Base64
  //   const base64Data = btoa(vcfText);
  //   const dataUrl = `data:text/vcard;base64,${base64Data}`;
  
  //   // Create an anchor element and set its href to the data URL
  //   const element = document.createElement("a");
  //   element.href = dataUrl;
  //   element.download = "myContact.vcf";
  
  //   // Append the element to the DOM, trigger the download, and remove it
  //   document.body.appendChild(element);
  //   element.click();
  //   document.body.removeChild(element);
  // };
  // const downloadTxtFile = (vcfText) => {
  //   // Convert the vCard text to a Uint8Array
  //   const utf8Encoder = new TextEncoder();
  //   const utf8Array = utf8Encoder.encode(vcfText);
  
  //   // Encode the Uint8Array to Base64
  //   const base64Data = window.btoa(String.fromCharCode(...utf8Array));
  //   const dataUrl = `data:text/vcard;base64,${base64Data}`;
  
  //   // Create an anchor element and set its href to the data URL
  //   const element = document.createElement("a");
  //   element.href = dataUrl;
  //   element.download = "myContact.vcf";
  
  //   // Append the element to the DOM, trigger the download, and remove it
  //   document.body.appendChild(element);
  //   element.click();
  //   document.body.removeChild(element);
  // };
  const downloadTxtFile = (vcfText) => {
    // Convert the string to Base64 using a proper UTF-8 encoding
    const base64Data = Buffer.from(vcfText, 'utf-8').toString('base64');
    const dataUrl = `data:text/vcard;base64,${base64Data}`;
  
    // Create an anchor element and set its href to the data URL
    const element = document.createElement("a");
    element.href = dataUrl;
    element.download = "myContact.vcf";
  
    // Append the element to the DOM, trigger the download, and remove it
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };
  

  const getBase64FromUrl = async (url) => {
    const { data } = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const base64data = reader.result.split(",")[1]; // Extract the base64 part
        resolve(base64data);
      };
    });
  };

  const CreateVCard = async () => {
    
    const vCard = vCardsJS();
    const vCardString = vCard.getFormattedString();
    let fullName = props.data.fullName
    let company = props.data?.company;
    let department = props.data?.department;

    vCard.firstName = fullName;
    // vCard.lastName = fullName[fullName.length - 1];
    console.log("fullName",fullName);
    
    // vCard.fullName = fullName;

    
    vCard.organization = company;
    vCard.email = props.data?.email[0]?.link;
    vCard.workPhone = props.data?.mobileNumber[0]?.link;
    vCard.title = department;
    vCard.url = props.data?.website[0]?.link;
    console.log( decodeURI(props.data?.facebook?.[0]?.link),"encodeURI(props.data?.facebook?.[0]?.link)");
    
    // vCard.socialUrls['facebook'] = `${decodeURI(props.data?.facebook?.[0]?.link)}`;
    // vCard.socialUrls['instgram'] = decodeURI(props.data?.instgram?.[0]?.link);
    // vCard.socialUrls['linkedIn'] = decodeURI(props.data?.linkedIn?.[0]?.link);
    // vCard.socialUrls['snapchat'] = decodeURI(props.data?.snapChat?.[0]?.link);
    // vCard.socialUrls['whatsapp'] = decodeURI(props.data?.whatsApp?.[0]?.link);

    // vCard.socialUrls['twitter'] = "test"
    // const facebookLink = props.data?.facebook?.[0]?.link;
    const instagramLink = props.data?.instgram?.[0]?.link;
   
    try {
      const base64Img = await getBase64FromUrl(props.data.mainUserImg);
      vCard.photo.embedFromString(base64Img, "JPEG");
    } catch (error) {
      console.error("Error embedding the image", error);
    }

    return vCard.getFormattedString();
  };

  return (
    <button
      style={{ backgroundColor: props.color }}
      onClick={async () => downloadTxtFile(await CreateVCard())}
      className="save-button"
    >
      SAVE CONTACT
    </button>
  );
}
