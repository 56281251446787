import * as React from 'react';
import { useState,useEffect } from 'react';
import { Link,useSearchParams } from 'react-router-dom';
import axios from 'axios';
import "./userProfile.css";
import  ProfileImage  from './hamza.jpeg';
import  facebook  from './face-n-1.svg';
import  whatsApp  from './whatsapp-n-1.svg';
import  phone  from './phone-n-1.svg';
import  Twiter  from './x-n-1.svg';
import  Linkedin  from './linkedin-n-1.svg';
import  Telegram  from './Telegram22.svg';
import  Instagram  from './instaLastV.svg';
import  snap  from './snap-n-1.svg';
import  tiktok  from './tiktok-n-1.svg';
import  youtube  from './youTube-n-1.svg';
import  link  from './whebsiteLastV.svg';
import  Email  from './emailLastV.svg';
import  Pdf  from './pdf.svg';
import  Location  from './location-n.svg';
import  telegram  from './telegram-n.svg';
import  Pintrest  from './pintrest.svg';
import  RadiousImage  from './image-with-color.svg';
import  company  from './company.svg';
import SaveContac from "./saveContact";
import MuiImageSlider from "mui-image-slider";
import { Helmet, HelmetProvider } from 'react-helmet-async'; 
import UseSEO from '../Hoks';

function UserProfile(props) {
  const [data ,setData]=useState({})
  const [loading ,setLoading]=useState(0)
  const[searchParams] = useSearchParams()
  const[id,setId] = useState('')
  const[color,setColor] = useState('')

  
  

  function getUseInfo(id1) {
    
    axios.post('/get_Order_by_id', {id:id1})
    .then((result) => {
      console.log('result.data.order',result.data.order);
      setData(result.data.order)
      setColor(result.data.order.color)
      setLoading(1)
      callCEO()
     
    })
    .catch((error) => {
      console.error(error);
    });
    
  }
  useEffect(()=>{
    getIdValue()
  },[])
  async function getIdValue(params) {
    await setId(searchParams.get('id'))
    getUseInfo(searchParams.get('id'))
  }
  const svg = `
    <svg width="393" height="125" xmlns="http://www.w3.org/2000/svg">
      <path d="M-1 0.0539551L3.1 21.385C6.94048 41.3516 17.6167 59.3564 33.2932 72.3047C48.9698 85.2529 68.6675 92.3355 89 92.335H393V126.481H-1V0.0539551Z" fill="${color}"/>
    </svg>
  `;
  
  const encodedSvg = encodeURIComponent(svg).replace(/'/g, '%27').replace(/"/g, '%22');
  const backgroundImage = `url('data:image/svg+xml;charset=UTF-8,${encodedSvg}')`;
function callCEO(params) {
  UseSEO({
    title: `${data.fullName}`,
    description: `${data.department}`,
    image: data.mainUserImg,
    url:window.location.href,
    type: 'profile',
  });
}
    return (
     <>
     { loading &&
  <div class="container width-of-page">
    <div className='images-top'>
    <img src={data?.mainUserImg}  className='user-image' alt="react logo" />
    {/* <img className='img-color' src={RadiousImage}  alt="RadiousImage logo" /> */}
     <svg 
  width="100%" 
  height="137" 
  className="img-color" 
  viewBox="0 0 392 137" 
  fill="none" 
  xmlns="http://www.w3.org/2000/svg"
  preserveAspectRatio="none"
>
  <path 
    d="M392 87.3728V137L387.916 115.786C375.763 45.2173 344.18 16.7584 302.457 16.7584H0.00708008V0H305.105C318.722 0.358785 380.926 6.45813 392 87.3728Z" 
    fill={color} 
  />
</svg> 
    {/* <svg className='img-color'  width="100%" height="150" viewBox="0 0 393 125" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M-1 0.0539551L3.1 21.385C6.94048 41.3516 17.6167 59.3564 33.2932 72.3047C48.9698 85.2529 68.6675 92.3355 89 92.335H393V126.481H-1V0.0539551Z" fill={color}/>
</svg> */}
    </div>
    <div className='main-user-info'>
      <div>
    <div className='user-info user-info-new'>
      <div>
      <h1 className='inter'>
      {data.fullName}
      </h1>
      {data.department && <h2>{data.department}</h2>}
      {data.company && <p>{data.company}</p>}
      </div>
      {data.logoImage &&  <img src={data?.logoImage}  className='logo-image' alt="react logo" />}
    </div>
    </div>
    <div className='user-linkes'>
      {data.facebook.length > 0 &&
      
data.facebook.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor:color}} className='image-link'>
    <img src={facebook} alt="Facebook logo" />
    </div>
    
    <span>Facebook</span>
    <span>{data.type}</span>
  </a>
))
}
{data.whatsApp.length > 0 &&

data.whatsApp.map((data, index) => (
  <a style={{backgroundColor: data.color}} className='main-image-link' key={index} href={`https://wa.me/${data.link}`}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link '>
    <img src={whatsApp} alt="whatsApp logo" />
    </div>
    <span>WhatsApp</span>
    <span>{data.type}</span>
  </a>
))
}
{data.mobileNumber.length > 0 &&
data.mobileNumber.map((data, index) => (
  <a className='main-image-link' key={index} href={`tel:${data.link}`}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link '>
    <img src={phone} alt="phone logo" />
    </div>
    <span>phone</span>
    <span>{data.type}</span>
  </a>
))
}
{data.twiter.length > 0 &&
data.twiter.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
    
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={Twiter} alt="Twiter logo" />
    </div>
    <span>X</span>
    <span>{data.type}</span>
  </a>
))
}
{data.linkedin.length > 0 &&
data.linkedin.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={Linkedin} alt="Linkedin logo" />
    </div>
    <span>LinkedIn</span>
    <span>{data.type}</span>
  </a>
))
}
{data.instgram.length > 0 &&
data.instgram.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={Instagram} alt="Instagram logo" />
    </div>
    <span>Instagram</span>
    <span>{data.type}</span>
  </a>
))
}
{data.snapChat.length > 0 &&
data.snapChat.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={snap} alt="snap logo" />
    </div>
    <span>Snap</span>
    <span>{data.type}</span>
  </a>
))
}
{data.tikTok.length > 0 &&
data.tikTok.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={tiktok} alt="tiktok logo" />
    </div>
    <span>Tik Tok</span>
    <span>{data.type}</span>
  </a>
))
}
{data.telegram?.length > 0 &&
data.telegram?.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={Telegram} alt="telegram logo" />
    </div>
    <span>Telegram</span>
    <span>{data.type}</span>
  </a>
))
}
{data.pintrest?.length > 0 &&
data.pintrest?.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={Pintrest} alt="Pintrest logo" />
    </div>
    <span>Pinterest</span>
    <span>{data.type}</span>
  </a>
))
}
{data.youtube.length > 0 &&
data.youtube.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={youtube} alt="youtube logo" />
    </div>
    <span>YouTube</span>
    <span>{data.type}</span>
  </a>
))
}
{data.website.length > 0 &&
data.website.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
    <div style={{backgroundColor: color}} className='image-link'>
    <img src={link} alt="link logo" />
    </div>
    <span>Website</span>
    <span>{data.type}</span>
  </a>
))
}
{data.email.length > 0 &&
data.email.map((data, index) => (
  <a className='main-image-link' key={index} href={`mailto:${data.link}`}  rel="noopener noreferrer">
    <div style={{backgroundColor: color}} className='image-link'>
    <img src={Email} alt="Email logo" />
    </div>
    <span>Email</span>
    <span>{data.type}</span>
  </a>
))
}
{data.location.length > 0 &&
data.location.map((data, index) => (
  <a className='main-image-link' key={index} href={data.link}  rel="noopener noreferrer">
     <div style={{backgroundColor: color}} className='image-link'>
    <img src={Location} alt="Location logo" />
    </div>
    <span>Location</span>
    <span>{data.type}</span>
  </a>
))
}
{/* <a>
<img src={Location}  alt="Location logo" />
</a> */}
{/* <a>
<img src={telegram}  alt="telegram logo" />
</a> */}
    </div>
    {data.pdf.length > 0 &&
    <div className='main-pdf-link'>
    {data.pdf.length > 0 &&
   data.pdf.map((data, index) => (
  <a className='' key={index} href={`${data.link}`}  rel="noopener noreferrer">
    <div className='d-flex pdf-link'>
    <div style={{backgroundColor: color}} className='image-link'>
    <img src={Pdf} alt="Email logo" />
    </div>
    <p  style={{color: color ,fontSize:16 ,fontWeight:500}}>{data.type}</p>
    </div>
  </a>
))
}
</div>
}

    {data.orderImages.length > 0 &&
    <div className='gellary-of-user'>
      {/* <h2>
Gellary
      </h2> */}
    <MuiImageSlider className='gellary-of-user' images={data.orderImages} />
    </div>
    }
    </div>
    <SaveContac color={color} data={data} />
    <div style={{ backgroundImage}} className='footer'>
      <p>Soon the biggest network's opporient in the Middle East</p>
      
    </div>
  </div>}
  </>
  
    );
}
export default UserProfile;
