import React from 'react';
import { useRef, useState,useEffect } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { TextField,MenuItem,InputLabel,FormControl,Select } from '@mui/material';
import axios from 'axios';
import  facebook  from './facebook.png';
import  Instagram  from './instagram-24.svg';
import  BackBtn  from './backbtn.svg';
import  UploadFile  from './uploadFile.svg';
import  FacebookNew  from './facebookNew.svg';
import  Twiter  from './icons8-x.svg';
import  YouTube  from './youtube.svg';
import  WhatsApp  from './WhatsApp.svg';
import  LinkedIn  from './linkedin.svg';
import Telegram from './Telegram.svg'
import  Website  from './website.svg';
import  Email  from './email_icon.svg';
import  TikTok  from './tiktok.svg';
import  Location  from './location_icon.svg';
import  Phone  from './phone_icon.svg';
import  SnapChat  from './snapchat_icon.svg';
import FileUpload from "./uploadPdf";
import Alert from '@mui/material/Alert';
import  Pintrest  from './pintrest.svg';
import { useNavigate,useSearchParams } from 'react-router-dom';
import { HexColorPicker } from "react-colorful";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pdf from "./pdf.svg"
import DeleteIcon from '@mui/icons-material/Delete';



import "./order.css";

const style = {
    position: 'absolute',
    top: '0',
    right: '0',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    height:'100%',
    overflow: 'scroll',
    paddingBottum: 2
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      marginTop:60,
      justifyContent:'center',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      alignItems:'center',
    },
  }));


export default function EditOrder(props) {
  const navigate = useNavigate();
  const[searchParams] = useSearchParams()
  const fileInputRef = useRef(null);
  const logoInputRef = useRef(null);
    const [fullName, setFullName] = useState("");
    const [title, setTitle] = useState("");
    const [department, setDepartment] = useState("");
    const [company, setCompany] = useState("");
    const [pdf, setPdf] = useState([]);
    const [instgram, setInstgram] = useState([]);
    const [facebook, setFacebook] = useState([]);
    const [twiter, setTwiter] = useState([]);
    const [youtube, setYoutube] = useState([]);
    const [whatsApp, setWhatsApp] = useState([]);
    const [linkedin, setLinkedin] = useState([]);
    const [website, setWebsite] = useState([]);
    const [email, setEmail] = useState([]);
    const [tikTok, setTikTok] = useState([]);
    const [location, setLocation] = useState([]);
    const [mobileNumber, setMobileNumber] = useState([]);
    const [snapChat, setSnapChat] = useState([]);
    const [active, setActive] = useState('Information');
    const [items, setItems] = useState([]);
    const [imagePreviewUrl,setImagePreviewUrl] = useState([]);
    const [imagePreviewUrlLogo,setImagePreviewUrlLogo] = useState([]);
    const [selectedUserImage, setSelectedUserImage] = useState(null);
    const [selectedLogoImage, setSelectedLogoImage] = useState(null);
    const [haveNewLogo, setHaveNewLogo] = useState(1);
    const [telegram, setTelegram] = useState([]);
    const [pintrest, setPintrest] = useState([]);
    
    
    const [selectedType,setSelectedType] = useState(props.selectedType);
    const [multipleImagePreviews, setMultipleImagePreviews] = useState([]); 
    const [multipleImages, setMultipleImages] = useState([]);
    const multipleFileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [showErrorMessage, setShoErrorMessage] = useState(false);
  const [color, setColor] = useState("#6FBB45");
  const [loading ,setLoading]=useState(0)
  const deleteDiv = (id, stateName, setItems,label) => {
    stateName(prevItems => prevItems.filter(item => item.id != id));
    setItems(prevItems => prevItems.filter(item => item.id !== id || item.label != label));
  };
  async function  setAllData(data) {
    console.log("setAllData",data);
    
    setFullName(data.fullName)
    setDepartment(data.department)
    setColor(data.color)
    setCompany(data.company)
    setImagePreviewUrl(data.mainUserImg)
    setImagePreviewUrlLogo(data.logoImage)
    setMultipleImagePreviews(data.orderImages)
    setFacebook(data.facebook)
    setInstgram(data.instgram)
    setTwiter(data.twiter)
    setYoutube(data.youtube)
    setWhatsApp(data.whatsApp)
    setLinkedin(data.linkedin)
    setTelegram(data.telegram)
    setPintrest(data.pintrest)
    setWebsite(data.website)
    setPdf(data.pdf)
    setEmail(data.email)
    setTikTok(data.tikTok)
    setLocation(data.location)
    setMobileNumber(data.mobileNumber)
    setSnapChat(data.snapChat)
      appendToDiv2('Facebook', 'Facebook Link', setFacebook , data.facebook)
      appendToDiv2('Instagram', 'Instagram Link', setInstgram ,data.instgram)
      appendToDiv2('X.com', 'X Link', setTwiter ,data.twiter)
      appendToDiv2('YouTube', 'YouTube Link', setYoutube ,data.youtube)
      appendToDiv2('WhatsApp', 'WhatsApp phone', setWhatsApp ,data.whatsApp)
      appendToDiv2('LinkedIn', 'LinkedIn Link', setLinkedin ,data.linkedin)
      appendToDiv2('Telegram', 'Telegram Link', setTelegram ,data.telegram)
      appendToDiv2('Pintrest', 'Pintrest Link', setPintrest ,data.pintrest)
      appendToDiv2('Website', 'Website Link', setWebsite ,data.website)
      appendToDiv2('pdf', 'pdf Link', setPdf ,data.pdf)
      appendToDiv2('Email', 'Email', setEmail ,data.email)
      appendToDiv2('TikTok', 'TikTok Link', setTikTok ,data.tikTok)
      appendToDiv2('Location', 'Location Link', setLocation ,data.location)
      appendToDiv2('Phone Number', 'Phone Number', setMobileNumber ,data.mobileNumber)
      appendToDiv2('SnapChat ', 'SnapChat Link', setSnapChat ,data.snapChat)
    
    
    setLoading(1)
  }
  const appendToDiv2 = (name, label, stateName,stateValue) => {
    const facebookItems = (stateValue || []).map((fbLink, index) => ({
      id: index,
      name: name,
      label:label ,
      link: fbLink.link || '',
      type: fbLink.type || '',
      value:fbLink.link,
      stateName,
      stateValue:[]
  }));
  setItems(prevItems => [...prevItems, ...facebookItems]);
  };
  const appendToDiv = (name, label, stateName,stateValue) => {
    stateName(prevItems => [...prevItems, { id:stateValue.length, link : "", type:""}]); 
    setItems(prevItems => [...prevItems, { id:stateValue.length,name, label, stateName,stateValue, value: '' }]);
  };
  

    
    function createOrderFunction(params) {
      let id1 = searchParams.get('id')
      if (id1 == '67158797e3ed73acaa5f932e') {
        id1 = '674cc030add118a1444bb054'
      }

      if ((selectedType == 3 && fullName != "" && file!= null) ||selectedType != 3 ) {
        setLoading(0)
        setShoErrorMessage(false)
      const formData = new FormData();
      formData.append('fullName', fullName);
      formData.append('haveNewLogo', haveNewLogo);
      formData.append('title', title);
      formData.append('department', department);
      formData.append('company', company);
      formData.append('pdfFile',file);
      formData.append(`mainUserImg`, selectedUserImage);
      formData.append(`logoImage`, selectedLogoImage);
      formData.append(`orderId`, id1);
      
      formData.append(`color`, color);
      
      multipleImages.forEach((image) => {
        formData.append('orderImages', image);
      });
      
      const appendSerializedArray = (array, key) => {
        formData.append(key, JSON.stringify(array));
      };
      appendSerializedArray(facebook, 'facebook');
      appendSerializedArray(instgram, 'instgram');
      appendSerializedArray(twiter, 'twiter');
      appendSerializedArray(youtube, 'youtube');
      appendSerializedArray(whatsApp, 'whatsApp');
      appendSerializedArray(linkedin, 'linkedin');
      appendSerializedArray(telegram, 'telegram');
      appendSerializedArray(pintrest, 'pintrest');
      appendSerializedArray(website, 'website');
      appendSerializedArray(email, 'email');
      appendSerializedArray(tikTok, 'tikTok');
      appendSerializedArray(location, 'location');
      appendSerializedArray(mobileNumber, 'mobileNumber');
      appendSerializedArray(snapChat, 'snapChat');
      appendSerializedArray(selectedType, 'type');
      appendSerializedArray(pdf, 'pdf');
      console.log(facebook);
      
      
      axios.post('/edit_Order', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((result) => {
        setLoading(1)
        if (selectedType == 3) {
          navigate('/MenuOrders')
        }else if(selectedType == 2){
          navigate('/CompanyCard')
        }else if(selectedType == 1){
          navigate('/BasicCard')
        }
        // getPendingOrders();
      })
      .catch((error) => {
        console.error(error);
      });
     }
    else{
      setShoErrorMessage(true)

    }
    }
   
  const handleImageChange = (event,index) => {
    if (index == 1) {
      const file = event.target.files[0];
    setSelectedUserImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
    }else{
      const file = event.target.files[0];
    setSelectedLogoImage(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImagePreviewUrlLogo(reader.result);
      };
      setHaveNewLogo(2)
    }
    
  };
 
 
  function uploadProfileImage(params) {
    if (params == 2) {
    logoInputRef.current.click();
      
    } else {
    fileInputRef.current.click();
      
    }
  }


  const selectActiveInfoType = (type) => {
    setActive(type);
  };
  const changeState =(value,id,stateName) => {
    console.log("value",value);
    stateName(prevItems => {
      return prevItems.map(item => {
        if (item.id == id ) {
          console.log("item.id == id",item.id == id,{ ...item, link: value });
          
          return { ...item, link: value };
        }
        return item;
      });
    });
  };
  const changeType =(value,id,stateName) => {
    stateName(prevItems => {
      return prevItems.map(item => {
        if (item.id == id ) {
          console.log({ ...item, type: value });
          return { ...item, type: value };
        }
        return item;
      });
    });
  };
  function backFunction(params) {
    props.setNext(false)
  }
  const handleMultipleImageChange = (event) => {
    const files = Array.from(event.target.files);
  
    // Append new files to the existing array in state
    setMultipleImages((prevImages) => [...prevImages, ...files]);
  
    const previews = files.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    });
  
    Promise.all(previews).then((results) => {
      setMultipleImagePreviews((prevPreviews) => [...prevPreviews, ...results]);
    });
  };
  function getUseInfo(id1) {
    
    axios.post('/get_Order_by_id', {id:id1})
    .then((result) => {
      console.log('result.data.order',result.data.order);
      setSelectedType(result.data.order.type)
      setAllData(result.data.order)
    })
    .catch((error) => {
      console.error(error);
    });
    
  }
  const classes = useStyles();
  useEffect(()=>{
    getIdValue()
  },[])
  async function getIdValue(params) {
    // await setId(searchParams.get('id'))
    getUseInfo(searchParams.get('id'))
  }
 
  return (
    <>
    { loading == 0 ?
    <div className={classes.root}>
    <CircularProgress />
  </div>:
    <div className='create-order-div'>
    <Container className='main-container-div' >
      <div className='header-add-order'>
        <div>
          {/* <img className='pointer' onClick={backFunction} src={BackBtn}  alt="Back Icon" /> */}
        <p>Edit Order</p> 
        </div>
        <a className='pointer' onClick={() => createOrderFunction()}>save</a>
        
       
      </div>
   { showErrorMessage && <Alert severity="error">Please fill all information.</Alert>}
      <div className='switch-personal-info'>
      <p
        className={`info-type pointer ${active === 'Information' ? 'active' : ''}`}
        onClick={() => selectActiveInfoType('Information')}
      >
        Information
      </p>
      <p
        className={`info-type pointer ${active === 'Fields' ? 'active' : ''}`}
        onClick={() => selectActiveInfoType('Fields')}
      >
        Fields
      </p>
        </div>
        {active === 'Information' &&
      <div className='personal-info'>
        <div className='personal-info-input'>
          <p>Full Name</p>
          <TextField
          required
          id="outlined-required"
          label="Full Name"
          defaultValue={fullName}
          onChange={(e) => {
            setFullName(e.target.value);
        }}
          className='input-edit-default'
        />
        </div>
        {selectedType == 2 && 
        <div className='personal-info-input'>
          <p>Chose The Color</p>
          <HexColorPicker className='mt-3' color={color} onChange={setColor} />
        </div>
}
        
        {selectedType != 3 &&
        <>
        <div className='personal-info-input Affiliation-info'>
        <p>Affiliation</p>
        </div>
        {/* <div className='personal-info-input mt-3'>
        <p>Title</p>
          <TextField
          required
          id="outlined-required"
          label="Title"
          defaultValue={title}
          onChange={(e) => {
            setTitle(e.target.value);
        }}
          className='input-edit-default'
        />
        </div> */}
        <div className='personal-info-input mt-3'>
        <p>Department</p>
          <TextField
          required
          id="outlined-required"
          label="Department"
          defaultValue={department}
          onChange={(e) => {
            setDepartment(e.target.value);
        }}
          className='input-edit-default'
        />
        </div>
        <div className='personal-info-input mt-3'>
        <p>Company</p>
          <TextField
          required
          id="outlined-required"
          label="Company"
          defaultValue={company}
          onChange={(e) => {
            setCompany(e.target.value);
        }}
          className='input-edit-default'
        />
        </div>
        <div className='personal-info-input Affiliation-info'>
        <p>Profile Photo</p>
        </div>
        {imagePreviewUrl == "" || imagePreviewUrl == null ?
        <div className='personal-info-input mt-3 upload-image pointer'
        onClick={(e) => {
          uploadProfileImage(1);
      }}
      >
        <img src={UploadFile}  alt="Upload Image " />
        <p>Uplod image</p>
        <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange = {(e) =>{handleImageChange(e,1)}}
            />

        </div>:
        <div className='deleteImageDiv'>
        <img className='personal-info-input Affiliation-info' width={300} height={300} src={imagePreviewUrl} />
        <DeleteIcon  onClick={() => setImagePreviewUrl('')} className='personal-info-input cancel-icon' />
        </div>
        }
        <div className='personal-info-input Affiliation-info'>
        <p>Uplod Logo</p>
        </div>
        {imagePreviewUrlLogo == "" || imagePreviewUrlLogo == null ?
        <div className='personal-info-input mt-3 upload-image pointer'
        onClick={(e) => {
          uploadProfileImage(2);
      }}
      >
        <img src={UploadFile}  alt="Upload Image " />
        <p>Uplod Logo</p>
        <input
                type="file"
                ref={logoInputRef}
                style={{ display: 'none' }}
                onChange = {(e) =>{handleImageChange(e,2)}}
            />

        </div>:
        <div className='deleteImageDiv'>
        <img className='personal-info-input Affiliation-info' width={150} height={150} src={imagePreviewUrlLogo} />
        <DeleteIcon  onClick={() => {
           setImagePreviewUrlLogo('')
           setHaveNewLogo(3)
          }
           }
            className='personal-info-input cancel-icon' />
        </div>
        }
        {selectedType == 2 &&
        <>
        <div className='personal-info-input Affiliation-info'>
        <p>gallery Photo</p>
        </div>
         <div className='personal-info-input mt-3 upload-image pointer' onClick={() => multipleFileInputRef.current.click()}>
        <img src={UploadFile} alt="Upload Multiple Images" />
        <p>Upload Multiple Images</p>
        <input
          type="file"
          ref={multipleFileInputRef}
          style={{ display: 'none' }}
          onChange={handleMultipleImageChange}
          multiple 
        />
      </div>
     { multipleImagePreviews.length > 0 &&
      <div className='deleteImageDiv'>
      <div className='multiple-images-preview'>
        {multipleImagePreviews.map((image, index) => (
          <img key={index} className='personal-info-input Affiliation-info' width={100} height={100} src={image} alt={`Preview ${index + 1}`} />
        ))}
      </div>
        <DeleteIcon  onClick={() => setMultipleImagePreviews([])} className='personal-info-input cancel-icon' />
        </div>}
     
      </>}
        </>
        }
        

      </div>}
      {active === 'Fields' &&
      <div className='fields-info '>
        <div className='selected-Fields'>
          <div className='Fields-contaner'>
          {items.map((item, index) => (
          <div className='box-of-fields'>
            {console.log("items == >",items)
            }
            <div className='div-with-remove'>
          <h4>{item.name}</h4>
          <DeleteIcon className='cancel-icon'  onClick={() => deleteDiv(item.id, item.stateName, setItems,item.label)} />
          </div>
      
          <TextField
      required
      id="outlined4"
      label={item.label}
      name='facebookLink'
      defaultValue={item.value}
      className='input-edit-default'
      onChange={(e) => {
        changeState(e.target.value,item.id,item.stateName,item.stateValue);
    }}
    />
    {item.name == 'pdf' ?
     ( <TextField
      required
      id="outlined4"
      label='pdf lable'
      name='facebookLink'
      defaultValue=''
      className='input-edit-default'
      onChange={(e) => {
        changeType(e.target.value,item.id,item.stateName,item.stateValue);
    }}
    />):
    (<FormControl fullWidth className='select-type-menu'>
      <InputLabel id="demo-simple-select-label">Type</InputLabel>
      <Select
      key={item.id}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        defaultValue={item.type}
        label="Type"
        // onChange={handleChange}
        onChange={(e) => {
          changeType(e.target.value,item.id,item.stateName,item.stateValue);
      }}
      >
        <MenuItem value={'Personal'}>Personal</MenuItem>
        <MenuItem value={'Work'}>Work</MenuItem>
      </Select>
    </FormControl>)}
   
        </div>
        ))}
            

          </div>
          {selectedType == 3 ?
          <FileUpload file= {file} setFile = {setFile}/>:
          <p>Tap a field to add it to your card</p>}
        </div>
        {selectedType != 3 &&
      <div className='section-add-field'>
      <div
                className='btn-add-section'
                onClick={() => appendToDiv('Facebook', 'Facebook Link', setFacebook , facebook)}
              >
                <p>Facebook</p>
                <img src={FacebookNew}  alt="Facebook Icon" />
                
              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('Instagram', 'Instagram Link', setInstgram ,instgram)}
              >
                <p>Instagram</p>
                <img src={Instagram}  alt="Instagram Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('X.com', 'X Link', setTwiter ,twiter)}
              >
                <p>X.com</p>
                <img src={Twiter}  alt="Twiter Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('YouTube', 'YouTube Link', setYoutube ,youtube)}
              >
                <p>YouTube</p>
                <img src={YouTube}  alt="YouTube Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('WhatsApp', 'WhatsApp phone', setWhatsApp ,whatsApp)}
              >
                <p>WhatsApp</p>
                <img src={WhatsApp}  alt="WhatsApp Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('LinkedIn', 'LinkedIn Link', setLinkedin ,linkedin)}
              >
                <p>LinkedIn</p>
                <img src={LinkedIn}  alt="LinkedIn Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('Website', 'Website Link', setWebsite ,website)}
              >
                <p>Website</p>
                <img src={Website}  alt="Website Icon" />

              </div>
              {selectedType == 2 &&
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('pdf', 'pdf Link', setPdf ,pdf)}
              >
                <p>PDF</p>
                <img src={Pdf}  alt="Website Icon" />

              </div>}
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('Email', 'Email', setEmail ,email)}
              >
                <p>Email</p>
                <img src={Email}  alt="Email Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('TikTok', 'TikTok Link', setTikTok ,tikTok)}
              >
                <p>TikTok</p>
                <img src={TikTok}  alt="TikTok Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('Location', 'Location Link', setLocation ,location)}
              >
                <p>Location</p>
                <img src={Location}  alt="Location Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('Phone Number', 'Phone Number', setMobileNumber ,mobileNumber)}
              >
                <p>Phone</p>
                <img src={Phone}  alt="Phone Icon" />

              </div>
              <div
                className='btn-add-section'
                onClick={() => appendToDiv('SnapChat ', 'SnapChat Link', setSnapChat ,snapChat)}
              >
                <p>SnapChat</p>
                <img src={SnapChat}  alt="SnapChat Icon" />

              </div>
              <div
                              className='btn-add-section'
                              onClick={() => appendToDiv('Telegram', 'Telegram Link', setTelegram ,telegram)}
                            >
                              <p>Telegram</p>
                              <img src={Telegram}  alt="Telegram Icon" />
              
                            </div>
                            <div
                className='btn-add-section'
                onClick={() => appendToDiv('Pintrest', 'Pintrest Link', setPintrest ,pintrest)}
              >
                <p>Pintrest</p>
                <img src={Pintrest}  alt="Pintrest Icon" />

              </div>
              
              
              
          </div>}
      </div>}
    </Container>
    </div>}
    </>
  );
}